body {
  font-family: Verdana, sans-serif;
  line-height: 20px;
}
body .content-wrapper {
  width: 1200px;
  margin: 0 auto;
  min-height: 500px;
}
body .center,
body .tcentered {
  text-align: center;
}
body .dragHandle {
  cursor: pointer;
}
body h1 {
  font-size: 18px;
  font-weight: bold;
  color: #5F5B5B;
  line-height: 23px;
}
body h2 {
  font-size: 16px;
  font-weight: bold;
  color: #5F5B5B;
  line-height: 21px;
}
body .icon-padding {
  margin-right: 10px;
}
.help-block.confirmed {
  color: #a94442;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.user {
  font-weight: bold;
}
.user.main {
  color: #c88319;
}
.user.admin {
  color: #53b87d;
}
.user.cataloger {
  color: #3aadb4;
}
.user.uploader {
  color: #786ccd;
}
.user.scout {
  color: #333;
}
.user-avatar.main {
  box-shadow: 0px 0px 3px #c88319;
}
.user-avatar.admin {
  box-shadow: 0px 0px 3px #53b87d;
}
.user-avatar.cataloger {
  box-shadow: 0px 0px 3px #3aadb4;
}
.user-avatar.uploader {
  box-shadow: 0px 0px 3px #786ccd;
}
.user-avatar.scout {
  box-shadow: 0px 0px 3px #333;
}
.btn-primary {
  background-color: #39C;
  border: none;
}
.btn-primary:hover {
  background-color: #2f87b2;
}
.parameter-switch {
  display: block;
  margin: 0 auto;
}
.parameter-switch input[type=checkbox] {
  display: none;
}
.parameter-switch input[type=checkbox] + label {
  color: #c6c6c6;
  font-size: 12px;
  line-height: 34px;
  display: block;
  vertical-align: top;
  cursor: pointer;
  margin-bottom: 0;
}
.parameter-switch input[type=checkbox] + label .switch {
  display: block;
  float: left;
  width: 55px;
  height: 23px;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  background-color: #c6c6c6;
  position: relative;
  transition: background-color 0.3s ease;
}
.parameter-switch input[type=checkbox] + label .switch:before {
  display: block;
  position: absolute;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: #fff;
  line-height: 23px;
  left: 30px;
}
.parameter-switch input[type=checkbox] + label .switch .square {
  background-color: #fff;
  width: 17px;
  height: 17px;
  margin-top: 3px;
  margin-left: 4px;
  transition: margin-left 0.3s ease;
}
.parameter-switch input[type=checkbox]:checked + label {
  color: #337ab7;
}
.parameter-switch input[type=checkbox]:checked + label .switch {
  background-color: #6c99dd;
}
.parameter-switch input[type=checkbox]:checked + label .switch:before {
  left: 9px;
}
.parameter-switch input[type=checkbox]:checked + label .switch .square {
  margin-left: 34px;
}
.parameter-switch:nth-child(even) {
  background-color: #F3F3F3;
}
html[lang=en] .parameter-switch input[type=checkbox] + label .switch:before {
  content: 'No';
}
html[lang=lv] .parameter-switch input[type=checkbox] + label .switch:before {
  content: 'Nē';
}
html[lang=ru] .parameter-switch input[type=checkbox] + label .switch:before {
  content: 'Нет';
}
html[lang=en] .parameter-switch input[type=checkbox]:checked + label .switch:before {
  content: 'Yes';
}
html[lang=lv] .parameter-switch input[type=checkbox]:checked + label .switch:before {
  content: 'Jā';
}
html[lang=ru] .parameter-switch input[type=checkbox]:checked + label .switch:before {
  content: 'Да';
}
.permissions h1 {
  margin-left: 10px;
}
.permissions h2 {
  margin-top: 10px !important;
  margin-bottom: 5px !important;
}
.content-inner-wrapper {
  padding-top: 10px;
}
.content-inner-wrapper .right-side-content {
  float: right;
  width: 1000px;
}
.content-inner-wrapper:after {
  content: "";
  display: block;
  clear: both;
}
.navbar {
  margin-bottom: 0px;
}
.navbar .left-profile-dropdown .dropdown-toggle {
  padding: 5px;
}
.navbar .left-profile-dropdown .dropdown-toggle .taskbar-image {
  width: 40px;
  height: 40px;
  border-radius: 3px;
}
.dummy_play {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-indent: -9999px;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px;
  margin-top: -1px;
  -webkit-transition-property: hover;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition-property: background-color;
  -o-transition-duration: 0.15s;
  margin-left: 5px;
  background-color: #efefef;
  background-image: url(../img/arrow-right-white.png);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAKCAYAAABmBXS+AAAAbklEQ…wDSQLBVSBti27dJajkcSD2RJODO3wtkOOMz/tMSJJYAxMA5dmsL0IfubQAAAAASUVORK5CYII=);
  background-image: none, url(../img/arrow-right-white-2x.png);
  background-size: 9px 10px;
  background-repeat: no-repeat;
  background-position: 5px 50%;
}
.dummy_play.song {
  vertical-align: middle;
  margin-right: 3px;
}
header {
  position: relative;
  background: rgba(0, 0, 0, 0) url(../img/header.png) no-repeat top center;
  width: 100%;
  height: 258px;
  padding-top: 1px;
  border-bottom: 1px solid #EBEBEB;
}
header.front {
  border: none;
}
header .title-holder {
  position: absolute;
  bottom: 6px;
  left: 0;
}
header .title-holder .random-button {
  display: inline-block;
  vertical-align: middle;
}
header .title-holder .random-button:before {
  height: 100%;
  vertical-align: middle;
  content: "";
  display: inline-block;
}
header .title-holder .info-title {
  font-size: 18px;
  line-height: 23px;
  color: #5F5B5B;
  margin-bottom: 0px;
  max-width: 800px;
  font-weight: bold;
  display: inline-block;
  margin: 0;
  vertical-align: middle;
}
header .anime-links {
  position: absolute;
  bottom: 5px;
  right: 10px;
}
header .anime-links a {
  color: #5F5B5B;
  font-size: 16px;
}
header .compilation-next-previous {
  position: absolute;
  width: 100%;
  bottom: 0;
}
header .compilation-next-previous .previous-compilation,
header .compilation-next-previous .next-compilation {
  margin-bottom: 10px;
  display: block;
  left: 0;
  position: absolute;
  text-decoration: none;
  bottom: 0;
  z-index: 1;
  background-color: #FFF;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #EBEBEB;
  transition: background-color 0.3s;
}
header .compilation-next-previous .previous-compilation .breadcrumbs,
header .compilation-next-previous .next-compilation .breadcrumbs {
  width: 62px;
  border: 1px solid #EBEBEB;
}
header .compilation-next-previous .previous-compilation:hover,
header .compilation-next-previous .next-compilation:hover {
  background-color: #F9F9F9;
}
header .compilation-next-previous .next-compilation {
  left: auto;
  right: 0;
}
header .compilation-info-title {
  color: #5F5B5B;
  font-size: 21px;
  line-height: 22px;
  float: none;
  position: absolute;
  text-align: center;
  width: 100%;
  bottom: 0;
}
header .page-title {
  font-size: 23px;
  line-height: 28px;
  color: #5F5B5B;
  position: absolute;
  bottom: 6px;
  margin-bottom: 0px;
  left: 15px;
  max-width: 800px;
}
.disabled-box {
  border: 1px solid #E6E1E1;
  color: #ABA7A7;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  font-size: 12px;
}
.anime-card,
.compilation-card {
  padding-top: 10px;
  background-color: #FFF;
  position: relative;
  margin-bottom: 10px;
}
.dragHandle {
  margin-right: 5px;
}
.card {
  padding-top: 10px;
  background-color: #FFF;
  position: relative;
  margin-bottom: 10px;
}
.card:after {
  content: "";
  display: block;
  clear: both;
}
.card .left-side {
  display: table;
  width: 300px;
  float: left;
}
.card .left-side .sm2_button,
.card .left-side .dummy_play {
  margin-left: 0px;
}
.card .right-side {
  display: table;
  float: right;
  width: 868px;
  border-left: 3px solid #EBEBEB;
}
.card .dtr {
  display: table-row;
}
.card .dtr .dtd {
  display: table-cell;
  font-size: 11px;
  padding: 2px 6px;
}
.card .dtr .dtd:first-child {
  font-weight: bold;
  vertical-align: top;
  width: 125px;
}
.card .dtr:nth-child(even) .dtd {
  background-color: #F9F9F9;
}
.card .info-part {
  padding-right: 10px;
  border-right: 3px solid #EBEBEB;
}
.card .info-part .info-table {
  font-size: 11px;
  width: 100%;
}
.card .info-part .info-table td {
  padding: 2px 6px;
}
.card .info-part .info-table td:first-child {
  font-weight: bold;
  vertical-align: top;
}
.card .info-part .info-table td.first-td {
  min-width: 125px;
}
.card .info-part .info-table tr:nth-child(even) td {
  background-color: #F9F9F9;
}
.card h2 {
  padding: 3px 10px;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #EBEBEB;
  font-weight: bold;
  color: #5F5B5B;
}
table {
  max-width: 100%;
  background-color: rgba(0, 0, 0, 0);
  border-collapse: collapse;
  border-spacing: 0;
}
.info-part {
  padding-right: 10px;
  border-right: 3px solid #EBEBEB;
}
.info-part .info-table {
  font-size: 11px;
  width: 100%;
}
.info-part .info-table td {
  padding: 2px 6px;
}
.info-part .info-table td:first-child {
  font-weight: bold;
  vertical-align: top;
}
.info-part .info-table td.cover {
  padding: 0px;
}
.info-part .info-table td.first-td {
  min-width: 125px;
}
.info-part .info-table tr:nth-child(even) td {
  background-color: #F9F9F9;
}
.info-part .info-table .anime-cover {
  width: 300px;
}
.content-part h2 {
  padding: 3px 10px;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #EBEBEB;
  font-weight: bold;
  color: #5F5B5B;
}
.content-part .known-songs .songlists {
  margin-left: 20px;
  font-size: 11px;
  margin-bottom: 20px;
}
.content-part .add-to-compilation {
  margin: 0;
  padding: 1px 2px;
  height: 24px;
  width: 50px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.full_series {
  font-size: 11px;
  color: #695050;
  margin: 0;
}
.full_series ol {
  margin: 0 25px 40px 40px;
}
.full_series ol li {
  line-height: 20px;
}
.full_series ol li a {
  color: #695050;
}
.full_series ol li.active a {
  font-weight: bold;
}
.theme3 {
  width: 100%;
  font-size: 11px;
  margin-bottom: 15px;
}
.theme3 td,
.theme3 th {
  padding: 3px 5px;
}
.theme3 tr:nth-child(odd) td {
  background-color: #F3F3F3;
}
.theme3 .status {
  width: 134px;
}
.theme3 .offer {
  width: 60px;
}
.theme3 .song-controls {
  width: 35px;
}
.theme3.spec.auto {
  width: auto;
  margin: 0 auto;
}
.theme3.spec th {
  border-bottom: 3px solid #9792E0;
}
.theme3.spec.users th {
  border-bottom: 3px solid #EBEBEB;
}
.theme3.spec .play {
  width: 62px;
}
.theme3.spec .ex-play {
  width: 93px;
}
.theme3.spec .date {
  width: 135px;
}
.theme3.spec .appearances {
  margin-left: 5px;
  margin-right: 0;
}
.theme3.spec .links {
  width: 165px;
}
.theme3.spec .options {
  width: 45px;
}
.theme3.spec .datetime {
  width: 145px;
}
.theme3.logs {
  min-width: 700px;
}
.theme3 .sm2_button {
  vertical-align: middle;
  margin-left: 0;
}
.theme3 .info {
  width: 62px;
}
.theme3 .info.main,
.theme3 .info.uploader,
.theme3 .info.cataloger {
  width: 76px;
}
.theme3 .type {
  text-align: center;
  width: 50px;
}
.btn .glyphicon-random {
  margin-right: 3px;
  top: 2px;
}
.btn .glyphicon-remove {
  top: 2px;
}
.comments .comments-cont .comment-entry {
  margin: 0px 10px 13px 20px;
  padding-bottom: 7px;
  border-bottom: 5px solid #F3F3F3;
}
.comments .comments-cont .comment-entry.new-entry {
  display: none;
}
.comments .comment-add-image,
.comments .comment-cont-image {
  display: inline-block;
  width: 58px;
  margin-left: 20px;
  vertical-align: top;
}
.comments .comment-cont-image {
  margin-left: 0px;
}
.comments .comments-add {
  margin-top: 10px;
  padding-top: 10px;
}
.comments .comments-add-content {
  display: inline-block;
  width: 750px;
}
.comments .comment-content {
  display: inline-block;
  width: 790px;
}
.comments .comments-add-content .author,
.comments .comment-content .author,
.comments .comment-content .anime {
  display: inline-block;
  font-size: 11px;
}
.comments .comment-content .time {
  font-style: italic;
  margin-left: 10px;
  font-size: 11px;
  color: #AD9797;
  float: right;
}
.comments .comment-content .comment-action-delete {
  font-style: italic;
  margin-left: 10px;
  font-size: 11px;
  float: right;
}
.comments .comment-content .sec {
  font-size: 11px;
  color: #AD9797;
  margin-right: 5px;
  width: 25px;
  display: inline-block;
}
.comments .comment-content .comment-text {
  padding: 5px;
  font-size: 11px;
  color: #333;
  line-height: 18px;
  border-top: 1px dotted #EAE6E6;
}
.comments .comments-add-content textarea {
  width: 100%;
  display: block;
  margin: 0 0 10px 0;
  box-sizing: border-box;
  height: 100px;
  border: 1px solid #C1B9B9;
  box-shadow: none;
  font-size: 11px;
}
.comments .comments-add-content textarea.error {
  border: 1px solid red;
}
.comments .comments-add-content textarea:focus {
  border: 1px solid #49AFCD;
}
.comments .comments-cont .comment-cont-image a,
.comments .comment-add-image a {
  border: 1px solid #EBEBEB;
  border-radius: 5px;
  position: relative;
  width: 50px;
  height: 50px;
  display: inline-block;
}
.comments .comments-cont .comment-cont-image a .comment-avatar-img,
.comments .comment-add-image a .comment-avatar-img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.comments.profile .comment-content {
  width: 870px;
}
.comments.profile .comment-content .sec {
  width: auto;
}
.starsong,
.starsong-profile {
  display: inline-block;
  background: rgba(0, 0, 0, 0) url(../img/star2.png) no-repeat bottom left;
  width: 20px;
  height: 19px;
  vertical-align: middle;
  margin-left: 2px;
  margin-top: -3px;
}
.starsong.done,
.starsong-profile.done {
  background-image: url(../img/star.png);
}
.starsong.song,
.starsong-profile.song {
  margin-left: 0px;
}
.starsong:focus,
.starsong-profile:focus {
  outline: none;
}
.compilations-list {
  text-align: center;
  margin-top: 20px;
}
.compilations-list .compilation-entry {
  display: inline-block;
  width: 300px;
  padding-top: 10px;
  border-bottom: 3px solid #EBEBEB;
  vertical-align: top;
  text-decoration: none;
  margin-bottom: 20px;
  text-align: left;
  position: relative;
  transition: border .2s;
  margin-right: 20px;
}
.compilations-list .compilation-entry:hover {
  border-bottom: 3px solid #39C;
}
.compilations-list .compilation-entry:hover .compilation-cover img {
  opacity: 0.9;
}
.compilations-list .compilation-title {
  color: #5F5B5B;
  font-size: 14px;
  line-height: 18px;
  margin-top: 0px;
  text-align: center;
  font-weight: bold;
}
.compilations-list .compilation-cover {
  width: 302px;
  display: inline-block;
}
.compilations-list .compilation-cover img {
  width: 302px;
  height: 302px;
  border: 1px solid #EBEBEB;
  opacity: 1;
  transition: opacity .2s;
}
.compilations-list .compilation-preinfo {
  display: inline-block;
  width: 240px;
  color: #5F5B5B;
  font-size: 11px;
  padding: 3px 10px;
}
.compilations-list .compilation-preinfo td:first-child {
  font-weight: bold;
  padding-right: 10px;
}
table.theme {
  margin: 20px auto 30px auto;
  width: 1100px;
  border-collapse: separate !important;
  border-spacing: 0;
  border: 1px solid #d6d5d2;
}
table.theme th,
table.theme td {
  border: none !important;
  padding: 4px 10px !important;
  font-size: 13px;
}
table.theme tr:nth-child(odd) td {
  background-color: rgba(243, 243, 243, 0.7);
}
table.theme2 tr td,
table.theme2 tr th {
  padding: 3px 5px;
  border: none;
  font-size: 11px;
  line-height: 20px;
  vertical-align: middle;
}
table.theme2 tr:nth-child(even) td {
  background-color: #F3F3F3;
}
footer {
  margin-top: 100px;
}
footer .upper-footer {
  border-top: 1px solid #e7e7e7;
  background-color: #f8f8f8;
  min-height: 50px;
}
footer .lower-footer {
  background-color: #ebebeb;
}
footer .utility-nav a {
  text-transform: uppercase;
  display: inline-block;
  margin: 0px 22px;
  color: #696969;
  font-size: 11px;
  font-weight: bold;
}
footer .inner-footer {
  margin: 0 auto;
  width: 1200px;
  text-align: center;
  padding: 15px;
  font-size: 11px;
  color: #333333;
}
.pagination-holder {
  text-align: center;
}
.pagination-holder .pagination li a,
.pagination-holder .pagination li span {
  font-size: 12px;
  padding: 5px 10px;
}
.pagination-holder .pagination li.active span,
.pagination-holder .pagination li.active a:hover,
.pagination-holder .pagination li.active a:focus {
  background-color: #39C;
  border-color: #39C;
}
table {
  border-radius: 4px;
}
table.auto {
  width: auto;
}
table.series {
  min-width: 700px;
}
table.striped {
  width: 100%;
  font-size: 11px;
  margin-bottom: 20px;
}
table.striped tr:nth-child(odd) td {
  background-color: #F3F3F3;
}
table.striped th,
table.striped td {
  padding: 3px 5px;
}
.profile-left-side {
  width: 300px;
  float: left;
  padding-right: 10px;
}
.profile-left-side .user-avatar {
  width: 100px;
  float: left;
  margin-right: 10px;
}
.profile-left-side .user-avatar img {
  width: 100px;
  height: 100px;
}
.profile-left-side td:first-child {
  font-weight: bold;
}
.profile-left-side .about {
  font-weight: normal;
  margin-left: 25px;
}
.profile-stats {
  font-size: 11px;
  width: 100%;
}
.profile-stats #avatar-uploader {
  font-weight: normal;
}
.profile-stats td {
  padding: 2px 6px;
  vertical-align: top;
}
.profile-stats .uanb {
  padding: 0 0 10px 0;
}
.profile-stats tr:nth-child(even) td {
  background-color: #F9F9F9;
}
.profile-stats .user {
  font-size: 18px;
  display: block;
}
.profile-right-side {
  float: left;
  width: 900px;
  border-left: 3px solid #EBEBEB;
  min-height: 400px;
}
.profile-right-side .password-email .btn {
  padding: 4px 12px;
}
.profile-right-side .stared-notice {
  font-style: italic;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #A7A7A7;
}
.profile-right-side .sm2_button {
  vertical-align: middle;
  margin-right: 5px;
}
.profile-right-side .profile-block {
  margin-left: 10px;
  margin-bottom: 30px;
}
.profile-right-side .avatar-notice {
  font-size: 11px;
  font-style: italic;
}
.profile-right-side h2 {
  padding: 3px 10px;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #EBEBEB;
  font-weight: 700;
}
.profile-right-side h3 {
  padding: 0px;
  font-size: 13px;
  line-height: 20px;
  margin: 20px 0 20px 0;
  color: #565151;
  font-weight: 700;
}
.profile-right-side input[type="text"],
.profile-right-side input[type="password"],
.profile-right-side select,
.profile-right-side textarea {
  width: 100%;
}
.profile-right-side label {
  font-weight: 400;
  font-size: 12px;
}
.profile-right-side .profile-tabs {
  padding-left: 10px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.profile-right-side .profile-tabs li {
  margin-left: 5px;
}
.profile-right-side .profile-tabs li a {
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0);
}
.profile-right-side .profile-tabs li.active a {
  border: 1px solid #ddd;
  border-bottom-color: rgba(0, 0, 0, 0);
}
.profile-right-side .form-horizontal .checkbox {
  min-height: 20px;
  padding-top: 3px;
}
.profile-right-side input,
.profile-right-side textarea {
  box-sizing: border-box;
  padding: 14px 10px;
}
.profile-right-side textarea {
  padding: 4px 10px;
}
.new-button {
  background-image: none;
  box-shadow: none;
}
.form-horizontal .controls {
  margin-left: 210px;
}
.form-horizontal.modal-body .control-label {
  width: 33.33333333%;
  text-align: right;
  font-weight: normal;
  font-size: 12px;
}
.modal {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}
.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal-backdrop {
  background-color: black;
}
.modal-backdrop.in {
  opacity: .2;
}
.modal-content {
  box-shadow: 0 5px 15px rgba(171, 171, 171, 0.5) !important;
}
.notice {
  font-style: italic;
  font-size: 12px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #A7A7A7;
}
label.checkbox {
  margin-left: 50px;
}
.profile-right-side .opb {
  color: red;
}
.profile-right-side .change-notice {
  font-size: 12px;
  width: 500px;
  display: inline-block;
  text-align: center;
  margin-left: 50px;
  margin-top: 15px;
}
.profile-right-side .avatar-error {
  font-size: 12px;
  display: block;
  margin-top: 15px;
  color: red;
}
.appearances {
  width: 26px;
  height: 22px;
  line-height: 22px;
  font-size: 11px;
  padding: 0px;
  text-align: center;
  display: inline-block;
  margin-right: 5px;
  text-decoration: none;
  border-radius: 3px;
}
.appearances.btn-candidate {
  color: #fff;
  background-color: #9792E0;
}
.appearances.btn-candidate:hover {
  background-color: #726CD0;
}
.appearances.btn-candidate.done {
  background-color: #BCBEBC;
}
.appearances.btn-candidate.done:hover {
  background-color: #888C88;
}
.appearances.btn-final {
  color: #fff;
  background-color: #5cb85c;
}
.appearances.btn-final:hover {
  background-color: #449d44;
}
.appearances:nth-child(n+5) {
  margin-top: 5px;
}
.appearances:hover,
.appearances:focus {
  text-decoration: none;
}
.anime-songs .info {
  width: 62px;
}
.anime-songs .info.main {
  width: 76px;
}
.anime-songs .type {
  width: 50px;
}
.anime-songs .typecont {
  position: relative;
}
.star-songs .info {
  width: 62px;
}
.songs-list .play {
  width: 72px;
}
.songs-list .sm2_button {
  margin-left: 0;
}
.offer-songs .info,
.final-songs .info,
.votes-songs .info {
  width: 75px;
}
#sortAnimeSongs td,
#sortSeries td {
  /* Safari */
  transition: background-color 0.3s;
}
#sortAnimeSongs .dragging td,
#sortSeries .dragging td {
  background-color: #B3E6FF;
}
.ajax-field-change {
  position: relative;
}
.ajax-field-change .field-save {
  display: none;
  position: absolute;
  right: -20px;
  top: 0;
  z-index: 1;
}
.ajax-field-change .field-save a {
  display: block;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #FFF;
  color: black;
  transition: all .3s;
}
.ajax-field-change .field-save:hover a {
  background-color: #B3E6FF;
  border-color: #B3E6FF;
}
.ajax-field-change.active .field-save {
  display: block;
}
.ajax-field-change.left .field-save {
  top: 1px;
}
.ajax-field-change.left .field-save a {
  padding: 0px 5px;
}
.cke_chrome {
  box-shadow: none;
}
.cke_inner .cke_top {
  background-image: none;
  background-color: white;
}
.year-outer-wrapper {
  padding-top: 10px;
}
.year-outer-wrapper:after {
  display: block;
  content: "";
  clear: both;
}
.year-menu,
.user-menu {
  float: left;
  width: 90px;
  min-height: 500px;
  border-right: 3px solid #EBEBEB;
}
.year-menu ul,
.user-menu ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.year-menu ul li,
.user-menu ul li {
  text-align: center;
  line-height: 20px;
  background-color: #F8F8F8;
  border-bottom: 1px solid white;
}
.year-menu ul li a,
.user-menu ul li a {
  padding: 3px 10px;
  display: block;
  color: #777;
  text-decoration: none;
}
.year-menu ul li.active,
.user-menu ul li.active,
.year-menu ul li:hover,
.user-menu ul li:hover {
  background-color: #39C;
}
.year-menu ul li.active a,
.user-menu ul li.active a,
.year-menu ul li:hover a,
.user-menu ul li:hover a {
  color: white;
}
.user-menu {
  width: 200px;
}
.user-menu ul li {
  text-align: left;
}
.user-menu ul li a {
  font-size: 11px;
}
.anime-year-list,
.recent-anime-list {
  width: 1100px;
  margin: auto;
  float: right;
  line-height: 0;
}
.anime-year-list .anime-entry,
.recent-anime-list .anime-entry {
  display: inline-block;
  position: relative;
  width: 220px;
  height: 330px;
}
.anime-year-list .anime-entry img,
.recent-anime-list .anime-entry img {
  width: 220px;
  height: 330px;
  display: block;
  transition: opacity 0.3s ease-in-out;
}
.anime-year-list .anime-entry .title-wrapper,
.recent-anime-list .anime-entry .title-wrapper {
  padding: 10px 14px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.anime-year-list .anime-entry .title-wrapper .title,
.recent-anime-list .anime-entry .title-wrapper .title {
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  color: #fff;
}
.anime-year-list .anime-entry:hover img,
.recent-anime-list .anime-entry:hover img {
  opacity: 0.9;
}
.anime-year-list .light-alfa-blue,
.recent-anime-list .light-alfa-blue {
  background-color: rgba(51, 153, 204, 0.9);
}
.recent-anime-list {
  float: none;
  width: auto;
  text-align: center;
}
.recent-anime-list .anime-entry {
  margin-right: 7px;
}
.compilation-card .compilation-cover img {
  width: 300px;
}
.compilation-card .compilation-songs .info.comp {
  width: 56px;
}
.compilation-card .compilation-songs .vote {
  width: 80px;
}
.compilation-card .compilation-songs .compsongstatus {
  display: inline-block;
  width: 16px;
  height: 19px;
  background-color: rgba(0, 0, 0, 0);
  background-image: url(../img/ch_e.png);
  background-repeat: no-repeat;
  background-size: 16px 18px;
  background-position: 0px 0px;
  vertical-align: middle;
}
.compilation-card .compilation-songs .compsongstatus.done {
  background-image: url(../img/ch_a.png);
}
.compilation-card .compilation-songs .compsongstatus.confirm-comp-song {
  cursor: hand;
  cursor: pointer;
}
.compilation-card .compilation-songs .dummy {
  display: inline-block;
  width: 16px;
  height: 19px;
  vertical-align: middle;
}
.compilation-card .compilation-songs .voting {
  width: 30px;
  text-align: center;
}
.compilation-card .compilation-songs .voting .voteicon {
  display: none;
}
.compilation-card .compilation-songs .voting .voteicon.glyphicon-thumbs-up {
  top: 2px;
}
.compilation-card .compilation-songs .voting:hover .votescore {
  display: none;
}
.compilation-card .compilation-songs .voting:hover .voteicon {
  display: inline-block;
}
.star-user:after {
  content: ",";
  display: inline;
}
.star-user:last-child:after {
  content: "";
}
.songs-table {
  width: 100%;
  font-size: 11px;
  margin-bottom: 50px;
}
.songs-table tr td,
.songs-table tr th {
  padding: 2px 6px;
}
.songs-table tr.vote:nth-child(odd) td {
  background-color: #F9F9F9;
}
.songs-table .comsongstatus0 th {
  color: #FFF;
  background-color: #9792E0;
}
.songs-table .comsongstatus1 th {
  color: #FFF;
  background-color: #5cb85c;
}
.songs-table .comsongstatus2 th {
  color: #FFF;
  background-color: #BCBEBC;
}
.songs-table .empty td {
  background-color: #F9F9F9;
}
.songs-table .song-app {
  color: white;
}
.songs-table .song-app:hover {
  text-decoration: none;
}
.card.song .left-side,
.card.log .left-side {
  width: 520px;
}
.card.song .right-side,
.card.log .right-side {
  width: 670px;
}
.card.song .delete-compsong {
  color: black;
}
.card.log .right-side .dtr .dtd:first-child {
  width: 170px;
}
@-webkit-keyframes barberpole {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 45px 45px;
  }
}
@keyframes barberpole {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 45px 45px;
  }
}
#anime-cover-upload,
#compilation-cover-upload,
#anime-song-upload {
  display: none;
}
#ac-dropzone img,
#song-dropzone img {
  display: block;
}
#ac-dropzone:after,
#song-dropzone:after {
  content: "";
  display: block;
  background-image: linear-gradient(-45deg, rgba(51, 153, 204, 0.5) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(51, 153, 204, 0.5) 50%, rgba(51, 153, 204, 0.5) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-size: 45px 45px;
  -webkit-animation: barberpole 1s linear infinite;
  animation: barberpole 1s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
#ac-dropzone.in:after,
#song-dropzone.in:after {
  opacity: 1;
}
#ac-dropzone.in.hover:after,
#song-dropzone.in.hover:after {
  background-image: linear-gradient(-45deg, rgba(92, 184, 92, 0.5) 25%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0) 50%, rgba(92, 184, 92, 0.5) 50%, rgba(92, 184, 92, 0.5) 75%, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0));
}
#ac-dropzone {
  border: 1px solid #EBEBEB;
  position: relative;
  margin-bottom: 10px;
}
#song-dropzone {
  position: relative;
  height: 40px;
  width: 100%;
  margin-bottom: 5px;
}
table.roles.spec {
  min-width: 300px;
}
table.roles.spec .options {
  width: 100px;
}
.custom-input-group {
  text-align: center;
  margin: 50px auto 40px auto;
  width: 600px;
}
.custom-input-group .search-field,
.custom-input-group .add-series,
.custom-input-group .series-title {
  width: 500px;
}
.custom-input-group .type-block {
  margin: 10px 0;
}
.custom-input-group.serie-info {
  margin: 50px 0 40px 0;
}
.custom-input-group .sort-by {
  margin-top: 15px;
}
.custom-input-group.add-compilation {
  text-align: right;
}
.custom-input-group.add-compilation .form-inline {
  margin-bottom: 15px;
}
.custom-input-group.add-compilation .form-inline input {
  text-align: left;
  width: 350px;
}
.chosen-containeris {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
}
.chosen-containeris .chosen-container {
  text-align: left;
}
.chosen-containeris .chosen-container-single .chosen-single {
  background: white;
  height: 36px;
  line-height: 32px;
}
.chosen-containeris .chosen-container-single .chosen-single div {
  top: 5px;
}
.play-icon {
  background-color: #CCC;
  background-image: url(../img/arrow-right-white.png);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAKCAYAAABmBXS+AAAAbklEQ…wDSQLBVSBti27dJajkcSD2RJODO3wtkOOMz/tMSJJYAxMA5dmsL0IfubQAAAAASUVORK5CYII=);
  background-image: none, url(../img/arrow-right-white-2x.png);
  background-size: 9px 10px;
  background-repeat: no-repeat;
  background-position: 5px 50%;
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-indent: -9999px;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px;
  margin-top: -1px;
}
.news {
  width: 500px;
  float: left;
}
.current-compilation {
  float: right;
  width: 400px;
}
.block-holder {
  margin-bottom: 50px;
}
.block-holder:after {
  content: "";
  display: block;
  clear: both;
}
.block-holder h2 {
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 15px;
}
.alert {
  margin-bottom: 10px;
}
.avatar-upload-button {
  position: relative;
  overflow: hidden;
}
.avatar-upload-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}
.form-horizontal .control-label {
  padding-top: 7px;
  margin-bottom: 0;
  text-align: right;
}
.container {
  width: 1200px !important;
}
@media (max-width: 767px) {
  .navbar-header {
    float: left;
  }
  .navbar {
    border-radius: 4px;
    min-width: 1200px;
  }
  .nav-tabs-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }
  .nav-tabs-justified > .active > a,
  .nav-tabs-justified > .active > a:hover,
  .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
  .nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  .nav-justified > li > a {
    margin-bottom: 0;
  }
  .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }
  .nav-tabs.nav-justified > .active > a,
  .nav-tabs.nav-justified > .active > a:hover,
  .nav-tabs.nav-justified > .active > a:focus {
    border-bottom-color: #fff;
  }
  .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
  }
  .nav-tabs.nav-justified > li > a {
    margin-bottom: 0;
  }
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
  .container {
    min-width: 1200px;
  }
  .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;
  }
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-collapse.in {
    overflow-y: visible;
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
  .container > .navbar-header,
  .container-fluid > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .navbar-static-top {
    border-radius: 0;
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    border-radius: 0;
  }
  .navbar-toggle {
    display: none;
  }
  .navbar-nav {
    float: left;
    margin: 0;
  }
  .navbar-nav > li {
    float: left;
  }
  .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .navbar-nav.navbar-right:last-child {
    margin-right: -15px;
  }
  .navbar-left {
    float: left !important;
  }
  .navbar-right {
    float: right !important;
  }
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .radio,
  .navbar-form .checkbox {
    display: inline-block;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .navbar-form .radio input[type="radio"],
  .navbar-form .checkbox input[type="checkbox"] {
    float: none;
    margin-left: 0;
  }
  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
  .navbar-form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    box-shadow: none;
  }
  .navbar-form.navbar-right:last-child {
    margin-right: -15px;
  }
  .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px;
  }
  .navbar-text.navbar-right:last-child {
    margin-right: 0;
  }
}
.left-profile-dropdown .fa-sign-out,
.left-profile-dropdown .fa-user {
  margin-right: 10px;
}
.progress {
  margin-left: 20px;
  margin-right: 20px;
  display: none;
}

/*# sourceMappingURL=common.css.map */
